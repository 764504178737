import dessertscl from './DrinkMenuPage.module.css'

import mainMenu from '../../assets/images/dsPhotos/icons/menus/mainMenu.JPG'
import lunch from '../../assets/images/dsPhotos/icons/menus/lunch.JPG'
import drinks from '../../assets/images/dsPhotos/icons/menus/drinks.JPG'
import desserts from '../../assets/images/dsPhotos/icons/menus/desserts.JPG'
import MenuTemplateElement from '../../components/Menu/MenuTemplate.js'
import MenuTemplateElementSides from '../../components/Menu/MenuTemplateSides'
import DessertsTemplateElement from './DrinkTemplate'
import { NavLink } from 'react-router-dom'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack.js'
import drinksImg from '../../assets/images/dsPhotos/icons/DosSombrerosDrinks.jpg'

 

const DESSERTS_DATA = [
    {
        Title: 'Galanti Prosecco Extra Dry, (Italy)',
        Description: 'BRONZE (NV) International Wine & Spirit Competition 2014.Fresh, vibrant & refreshing.' ,
        Price: '£25.00',
        ExtraInfo1: false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'Cullinan View Chenin Blanc, Western Cape, (South Africa)',
        Description: 'A great partner for chicken and vegetable dishes with a touch of sweetness to them. Bottle for £16.00',
        Price: '£16.00',
        ExtraInfo1:  '250ML',
        ExtraPrice1: '£5.50',
        ExtraInfo2: '175ML',
        ExtraPrice2: '£4.25',
        ExtraDetail: false,
    },
    {
        Title: 'Parini Pinot Grigio(Italy)',
        Description: 'Distinctive nose of wild flowers, with touches of honey and banana; soft, fresh and lively with notes of ripe pear. Bottle for £18.00',
        Price: '£18.00',
        ExtraInfo1:  '250ML',
        ExtraPrice1: '£6.25',
        ExtraInfo2: '175ML',
        ExtraPrice2: '£4.75',
        ExtraDetail: false,
    },
    {
        Title: 'Errázuriz 1870 Peñuelas Block Sauvignon Blanc, Casablanca Valley, (Chile) ',
        Description: 'Classic Casablanca herbs, cut grass and tropical fruit. Bottle for £21.00',
        Price: '£21.00',
        ExtraInfo1:  '250ML',
        ExtraPrice1: '£7.00',
        ExtraInfo2: '175ML',
        ExtraPrice2: '£5.50',
        ExtraDetail: false,
    },
    {
        Title: 'Don Jacobo Rioja Blanco, Bodegas Corral, (Spain)',
        Description: 'Light and refreshing, a mouth-watering partner for salads and seafood',
        Price: '£26.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'Errázuriz Chardonnay Wild Ferment, Casablanca Valley, (Chile)',
        Description: 'SILVER (2013) International Wine Challenge 2014. Just perfect with chicken and spice',
        Price: '£32.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'Antonio Rubini Pinot Grigio Rosé delle Venezie, (Italy)',
        Description: 'Soft and fruity ‘PG’ rosé, good with chicken, salads and seafood. Bottle for £18.00',
        Price: '£18.00',
        ExtraInfo1:  '250ML',
        ExtraPrice1: '£6.25',
        ExtraInfo2: '175ML',
        ExtraPrice2: '£4.75',
        ExtraDetail: false,
    },
    {
        Title: 'El Velero Tempranillo Tinto Albali, Valdepeñas, (Spain)',
        Description: 'Fruit-driven, a good match for light meat and vegetable based dishes. Bottle for £16.00',
        Price: '£16.00',
        ExtraInfo1:  '250ML',
        ExtraPrice1: '£5.50',
        ExtraInfo2: '175ML',
        ExtraPrice2: '£4.25',
        ExtraDetail: false,
    },
    {
        Title: 'Tierra Merlot, Central Valley, (Chile)',
        Description: 'A great all-rounder, as good with food as it is on it’s own. Bottle for £18.00',
        Price: '£18.00',
        ExtraInfo1:  '250ML',
        ExtraPrice1: '£6.25',
        ExtraInfo2: '175ML',
        ExtraPrice2: '£4.75',
        ExtraDetail: false,
    },
    {
        Title: 'Callia Lunaris Malbec, San Juan, (Argentina)',
        Description: 'Turns the heat up when paired with spicy dishes or peps up grilled or roasted beef dishes. Bottle for £21.00',
        Price: '£21.00',
        ExtraInfo1:  '250ML',
        ExtraPrice1: '£7.00',
        ExtraInfo2: '175ML',
        ExtraPrice2: '£5.50',
        ExtraDetail: false,
    },
    {
        Title: 'Pablo Old Vine Garnacha, Calatayud, (Spain)',
        Description: 'Spicy, robust and powerful, just like the label!',
        Price: '£26.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'Portillo Pinot Noir, Uco Valley, Mendoza, (Argentina)',
        Description: 'Light on tannin, high on flavour, simply luscious.',
        Price: '£27.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'L.A. Cetto Petite Sirah, Valle de Guadalupe, (Mexico)',
        Description: 'A true taste of Mexico, the grape is similar to Shiraz, smooth yet spicy',
        Price: '£32.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'COCA COLA • DIET COKE • SPRITE • FANTA',
        Description: '',
        Price: '£2.95',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'FENTIMANS BOTANICALLY ORGANIC',
        Description: 'ROSE LEMONADE • GINGER BEER',
        Price: '£3.25',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'FOLKINGTON’S 100% FRUIT JUICE',
        Description: 'APPLE JUICE • ORANGE JUICE',
        Price: '£3.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'PINEAPPLE JUICE • CRANBERRY JUICE',
        Description: '',
        Price: '£2.75',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'STILL WATER • SPARKLING WATER',
        Description: '',
        Price: '£2.50',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'FRUIT SMOOTHIES',
        Description: 'STRAWBERRY • RASPBERRY • PASSION FRUIT • MANGO',
        Price: '£4.25',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'Pint of soft drink',
        Description: '',
        Price: '£3.50',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'FILTER COFFEE',
        Description: '',
        Price: '£2.75',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'ENGLISH BREAKFAST TEA • EARL GREY • CAMOMILE • GREEN TEA • PEPPERMINT • FRUIT TEA',
        Description: '',
        Price: '£2.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'LIQUEUR COFFEES',
        Description: 'FRENCH COFFEE (BRANDY) • IRISH COFFEE (WHISKEY) • BAILEYS COFFEE • JAMAICAN COFFEE (TIA MARIA) • MEXICAN COFFEE (OLMECA REPOSADO TEQUILA)',
        Price: '£4.50',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'MARGARITA',
        Description: 'Strawberry, Raspberry, Passion Fruit, Mango: Frozen or Classic Tequila blanco, triple sec, fruit puree and lime juice',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'Bloody mary',
        Description: 'vodka, tomato juice, Worcestershire sauce, salt, pepper and tabasco',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'FRENCH MARTINI',
        Description: 'Vodka, pineapple and chambord',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'COSMOPOLITAN',
        Description: 'Vodka, triple sec, lime and cranberry',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'AMARETTO SOUR',
        Description: 'Amaretto Disaronno, bitters and lemon juice',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'MOJITO',
        Description: 'Strawberry, Raspberry, Passion Fruit, Mango or Classic Rum, fresh mint, fresh lime and sugar',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'LONG ISLAND ICE TEA',
        Description: 'Vodka, rum, tequila, gin, triple sec, lemon juice and coke',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'BRIGHTON CALLING',
        Description: 'Gin, agave, fresh mint, cucumber, soda and a dash of apple juice',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'TEQUILA SUNRISE',
        Description: 'Tequila, orange juice and grenadine',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'CAIPIRINHA',
        Description: 'Cachaça, lime, sugar and crushed ice',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'SEX ON THE BEACH',
        Description: 'Vodka, archers, orange and cranberry juice',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'MEXICAN BUTTERFLY',
        Description: 'Olmeca reposado, malibu, pineapple juice and grenadine',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'PALOMA',
        Description: 'Tequila, lime juice, passion fruit puree and pineapple juice',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'MEXICAN WOO WOO',
        Description: 'Vodka, tequila, agave, cranberry and lime juice',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'DAIQUIRI',
        Description: 'Strawberry, Raspberry, Passion Fruit or Mango Rum, fresh lime, fruit puree and sugar syrup. Served frozen or classic',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'PIÑA COLADA',
        Description: 'Rum, Malibu, yoghurt liqueur, lime and pineapple juice',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'ZOMBIE',
        Description: 'White rum, dark rum, apricot brandy, sugar syrup and pineapple, orange and lime juice. Topped with grenadine',
        Price: '£7.25',
        ExtraInfo1:  '2 for',
        ExtraPrice1: '£13.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'JUGS OF COCKTAILS ',
        Description: '',
        Price: '£35.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'ESTRELLA DAMM (SPAIN) (4.6%) DRAUGHT BEER (PINT)',
        Description: '',
        Price: '£4.95',
        ExtraInfo1:  'HALF',
        ExtraPrice1: '£3.25',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'PISTONHEAD LAGER (SWEDEN) (4.6%) DRAUGHT BEER (PINT)',
        Description: '',
        Price: '£4.95',
        ExtraInfo1:  'HALF',
        ExtraPrice1: '£3.25',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'SOL BOTTLE BEER',
        Description: '',
        Price: '£3.95',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'PACIFICO CLARA (355ML) BOTTLE BEER',
        Description: '',
        Price: '£4.50',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'DESPERADOS BOTTLE BEER',
        Description: '',
        Price: '£4.20',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'BULMERS ORIGINAL CIDER',
        Description: '',
        Price: '£4.95',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'BULMERS PEAR CIDER',
        Description: '',
        Price: '£4.95',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'BULMERS CRUSHED BERRIES CIDER',
        Description: '',
        Price: '£4.95',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'Hefeweizen wheat ale (DAY OF THE DEAD BEER)',
        Description: '',
        Price: '£5.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'I.P.A (DAY OF THE DEAD BEER)',
        Description: '',
        Price: '£5.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'cubanisto (rum flavoured) (DAY OF THE DEAD BEER)',
        Description: '',
        Price: '£4.50',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'SPIRITS (SINGLE-25ML)',
        Description: 'ADD A MIXER FOR £1: AMARETTO • ARCHERS • BACARDI • BAILEYS • BRANDY • GIN • WILD TURKEY • JÄEGERMEISTER • MALIBU • SAMBUCA • SAILOR JERRY • BACARDI CARTA NEGRA • TIA MARIA • TUACA • VODKA • WHISKEY • LARIUS • SOBIESKI • AGUA RIVA TEQUILA • PAMPERO • PAMPERO ESPECIAL',
        Price: '£3.95',
        ExtraInfo1:  'DOUBLE (50ML)',
        ExtraPrice1: '£5.00',
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'JUG OF SANGRIA',
        Description: '',
        Price: '£15.95',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'JUG OF PIMM’S ',
        Description: '',
        Price: '£15.95',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'COCONUT 1800 TEQUILA',
        Description: '',
        Price: '£3.50',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'AQUA RIVA TEQUILA ',
        Description: 'BLANCO, REPOSADO',
        Price: '£3.50',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'OLMECA ALTOS TEQUILA',
        Description: 'BLANCO, REPOSADO',
        Price: '£4.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'CAZCABEL TEQUILA',
        Description: 'COFFEE, HONEY',
        Price: '£4.25',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'EL JIMADOR TEQUILA',
        Description: 'BLANCO, REPOSADO',
        Price: '£4.25',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'HERRADURA TEQUILA',
        Description: 'BLANCO, REPOSADO',
        Price: '£4.50',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'DON JULIO TEQUILA',
        Description: 'BLANCO, REPOSADO, ANĒJO',
        Price: '£5.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'PATRÓN TEQUILA',
        Description: 'BLANCO, REPOSADO, ANĒJO, CAFÉ, CITRÓNGE',
        Price: '£5.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'KAH TEQUILAS ',
        Description: '',
        Price: '£5.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'JOSÉ CUERVO LA FAMILIA TEQUILA',
        Description: '',
        Price: '£8.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
    {
        Title: 'DON JULIO 1942 TEQUILA',
        Description: '',
        Price: '£10.00',
        ExtraInfo1:  false,
        ExtraPrice1: false,
        ExtraInfo2: false,
        ExtraPrice2: false,
        ExtraDetail: false,
    },
]


function DessertsMenuPage(){
    return <div>


        <div>
            <div className={dessertscl.menus}>
                <div className={dessertscl.menuButtom}>
                    <NavLink to='/menus'><a className={dessertscl.menusImg} href='' target='_blank'><img src={mainMenu} alt=""/></a></NavLink>
                </div>
                {/*<div className={dessertscl.menuButtom}>
                    <NavLink to='/lunch-menu'><a className={dessertscl.menusImg} href='' target='_blank'><img src={lunch} alt=""/></a></NavLink>
                </div>*/}
                <div className={dessertscl.menuButtom}>
                    <NavLink to='/drink-menu'><a className={dessertscl.menusImg} href='' target='_blank'><img src={drinks} alt=""/></a></NavLink>
                </div>
                <div className={dessertscl.menuButtom}>
                    <NavLink to='/desserts-menu'><a className={dessertscl.menusImg} href='' target='_blank'><img src={desserts} alt=""/></a></NavLink>
                </div>

            </div>
            
            <div className={dessertscl.drinksMenuImg}>
                <img src={drinksImg} alt='Drinks'/>
            </div>
            <div className={dessertscl.menuLayout} >
                <p className={dessertscl.mTitle} >DRINKS</p>  {/*As the title refers to both columns, It's not put individually inside of each*/} 
                <DessertsTemplateElement List= {DESSERTS_DATA} /> {/*Pass a whole list as prop  1-2*/}
            </div>

            <div className={dessertscl.menuParagraphs}>
                <p>SEE OUR COCKTAIL AND SPECIAL TEQUILA MENU<span>!</span></p>
                <p>MAKE SURE YOU ENJOY OUR GREAT FOOD & DRINKS OFFERS</p>

            </div>

        </div>



    </div>;
}

export default DessertsMenuPage;